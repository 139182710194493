<template>
	<div class="feed">
		<!-- 导航栏 -->
		<van-nav-bar class="nav_bar_color" title="试乘试驾报告" left-text="返回" left-arrow @click-left="onClickLeft" fixed
			z-index="11" :border="false" placeholder>
		</van-nav-bar>
		<!-- 头部 -->
		<div class="head">

			<img style="width: 100%;" :src="serverUrl(feedbackinfo.head_image)" mode="widthFix">
			<div class="feed_concat">
				<div class="feed_concat_name">
					尊敬的{{feedbackinfo.name}}：
				</div>
				
				<div class="feed_concat_content">
					&nbsp;&nbsp;感谢您在{{getDateTime2(feedbackinfo.starttime).slice(5)}}参与了凯迪拉克{{feedbackinfo.model_name}}的试乘试驾体验，在本次体验中，您定制了{{feedbackinfo.score}}项专属试乘试驾体验项目，其中印象最为深刻与需要改善的项目体验是
				</div>
				
			</div>
			
			<div class="feed_list">
				<div class="feed_list_top">
					<span class="time">试驾时长</span>
					<span class="super">super cruise</span>
				</div>
				<div style="align-items: center;" class="feed_list_footer">
					<span class="super_tiile">{{feedbackinfo.cruise_distance}}<span>分钟</span></span><img src="@/assets/drivroute/icon_lucheng.png" mode="">
				</div>
			</div>
			
			<div class="feed_list">
				<div class="feed_list_top">
					<span class="time">试驾距离</span>
					<span class="super">super cruise</span>
				</div>
				<div style="align-items: center;" class="feed_list_footer">
					<span class="super_tiile">{{feedbackinfo.cruise_duration}}<span>公里</span></span><img style="width:76px;height: 70px;" src="@/assets/drivroute/icon_paodoa.png" mode="">
				</div>
			</div>
			
			<div class="feed_list" v-for="v,index in feedbackinfo.feedback" :key="index">
				<div class="feed_list_top">
					<span class="time">{{v.content}}</span>
					<span class="super">super cruise</span>
				</div>
				<div class="feed_list_footer">
					<span class="super_tiile">{{v.feedback}}<span>分</span></span>
					<span class="super_pj">您对此项的评价为：{{v.feedback}}分（1-10）</span>
				</div>
			</div>
			
		<!-- 个人信息组件 -->
		<div class="driveinfo_footer">
			<van-image class="center_avatar" round :src="serverUrl(feedbackinfo.avatar)" fit="cover"/>
			<span class="driveinfo_footer_name">{{feedbackinfo.bio}}</span>
			<span class="driveinfo_footer_title">您的专属销售顾问{{feedbackinfo.username}}随时为您服务</span>
		</div>
		
		<div class="driveinfo_user">
			<div class="">
				<img src="@/assets/drivroute/icon_dh.png" mode="">
				<span>{{feedbackinfo.user_contact}}</span>
			</div>
			<div class="">
				<img src="@/assets/drivroute/icon_sj.png" mode="">
				<span>{{feedbackinfo.store_contact}}</span>
			</div>
		</div>
		
		<div class="footer">
			未尽事宜后续再与您沟通联系，非常感谢支持
		</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data(){
			return{
				feedbackinfo:{},
				number:'CH240623010401312',
			}
		},
		mounted(){
			this.number = this.$route.query.number
			this.getfeedbackinfo()
		},
		methods:{
			// 时间格式化
			getDateTime2(timestamp) {
				const date = new Date(timestamp * 1000)
				const Y = date.getFullYear() + '年'
				const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
				const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日'
				return Y + M + D
			},
			// 获取详情
			async getfeedbackinfo(){
				const {data:res} = await this.$http.post('/api/scsj/h5_get_report_1_7',{number:this.number})
				if(res.code == 1){
					console.log(res.data.res)
					this.feedbackinfo = res.data.res
				}
			},
		}
		
		}
</script>

<style lang="scss" scoped>
.feed_list{
	margin: 20px 40px 0;
	height: 310px;
	background: linear-gradient(135deg, #2C28E8 0%, #237DFE 100%);
	border-radius: 16px;
	padding: 30px 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.feed_list_top{
		display: flex;
		justify-content: space-between;
		.time{
			width: 50%;
			font-size: 32px;
			font-family: Regular;
			font-weight: 400;
			color: #FFFFFF;
		}
		.super{
			width: 50%;
			text-align: right;
			font-size: 36px;
			opacity: 0.2;
			font-family: Regular;
			font-weight: 500;
			color: #FFFFFF;
		}
	}
	.feed_list_footer{
		display: flex;
		justify-content: space-between;
		img{
			width: 136px;
			height: 100px;
		}
		.super_tiile{
			font-size: 80px;
			font-family: Regular;
			font-weight: 400;
			color: #FFD974;
			span{
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
		.super_pj{
			padding-top: 50px;
			font-size: 24px;
			font-family: Regular;
			font-weight: 400;
			color: #FFFFFF;
		}
		
	}
}
	
	
.feed_concat{
	padding: 40px;
	.feed_concat_name{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 36px;
	}
	.feed_concat_content{
		padding: 30px 0;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #E7E7E7;
		line-height: 48px;
	}
	.feed_project{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		margin-top: 20px;
	}
	.feed_experience{
		padding: 32px 0;
		margin-bottom: 40px;
		border-bottom: 1px solid #333333;
		display: flex;
		justify-content: space-between;
		div{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		span{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #A1A1A1;
			line-height: 32px;
		}
	}
	.feed_list{
		.feed_list_title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 48px;
		}
		.feed_list_img{
			padding: 30px 50px 50px;
			display: flex;
			justify-content: space-between;
			img{
				width: 144px;
				height: 72px;
			}
		}
	}
	.feed_detail{
		padding: 32px 40px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		background: #222222;
		font-weight: normal;
		color: #C8C8C8;
		line-height: 48px;
		div:nth-child(2){
			margin-top: 48px;
		}
	}
	.feed_Score{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		margin-top: 60px;
	}
	.feed_Score_name{
		padding: 40px 0;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #A1A1A1;
	}
	.feed_line{
		height: 1px;
		background: #333333;
	}
	.feed_recommend{
		padding: 48px 0;
		.feed_recommend_title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 32px;
		}
		.feed_recommend_list{
			margin-top: 48px;
			display: flex;
			justify-content: space-between;
			.feed_recommend_Single{
				width: 148px;
				height: 100px;
				background: #333333;
				text-align: center;
				line-height: 100px;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
	}
}
.feed{
	padding-bottom: 150px;
	}
	
.driveinfo_footer{
	margin-top: 50px;
		padding: 40px;
		display: flex;
		align-items: center;
		flex-direction:column;
		.center_avatar{
			width: 120px;
			height: 120px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
		.driveinfo_footer_name{
			margin-top: 32px;
			font-size: 40px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 40px;
		}
		.driveinfo_footer_title{
			margin-top: 32px;
			font-size: 32px;
			color: #BFBFBF;
			line-height: 32px;
		}
	}
	
.driveinfo_user{
	text-align: center;
	div{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		img{
			width: 40px;
			height: 40px;
		}
		span{
			margin-left: 16px;
			font-size: 28px;
			font-family: Regular;
			font-weight: 400;
			color: #FFFFFF;
		}
	}
}

.footer{
	text-align: center;
	margin-top: 60px;
	font-size: 24px;
	font-family: Regular;
	font-weight: 400;
	color: #818181;
}
</style>